import React from "react";
import {MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBRow} from "mdb-react-ui-kit";
import Icon from "@mdi/react";
import {mdiAccountCircle, mdiChevronRight} from "@mdi/js";
import {MDBCardStatus, MDBCardStatusEnum} from "../mdb/MDBCardStatus";
import {BezoekSessieInstallatie} from "../../workers/shared/snapshot/bezoekSessieState";
import {MDBChip} from "../mdb/MDBChip";
import {useTechnici} from "../../redux/slices/technicus/hooks";
import {Assets} from "../../assets/assets";
import {useTranslation} from "../../utilities/i18nUtils";

export interface InstallatieCardProps {
    installatie: BezoekSessieInstallatie;
}

export const InstallatieCard: React.FC<InstallatieCardProps> = (props) => {

    const {t} = useTranslation("serviceadres");

    const {installatie} = props;

    const technici = useTechnici();

    let color: MDBCardStatusEnum | undefined = undefined;
    if (installatie.uitgevoerd) {
        color = MDBCardStatusEnum.DONE;
    } else if (installatie.bezig) {
        color = MDBCardStatusEnum.WARNING;
    }

    return (
        <MDBCard background="light" className="mt-2 mb-4 shadow-sm">
            <MDBCardStatus status={color}/>

            <MDBCardHeader>
                <MDBRow>
                    <MDBCol>
                        <span className="d-flex align-items-center">
                            <Icon path={Assets.orderTypes.installatie} size={1} className="me-1"/>
                            {t("algemeen:Titels.installatie", "Installatie")}
                        </span>
                    </MDBCol>

                    <MDBCol size="auto" className="d-flex">
                        {installatie.laatstActieveTechnicusId && (
                            <MDBChip className="my-0 me-0">
                                <Icon path={mdiAccountCircle} size={1}/>

                                {technici[installatie.laatstActieveTechnicusId]?.naam}
                            </MDBChip>
                        )}
                    </MDBCol>
                </MDBRow>
            </MDBCardHeader>
            <MDBCardBody>
                <MDBRow>
                    <MDBCol>
                        <MDBRow>
                            {installatie.uitTeVoerenWerk?.gebouw && (
                                <MDBCol sm="3" className="mb-2">
                                    <strong>{t("Labels.gebouw", "Gebouw")}</strong><br/> {installatie.uitTeVoerenWerk?.gebouw}
                                </MDBCol>
                            )}
                            {installatie.uitTeVoerenWerk?.verdieping && (
                                <MDBCol sm="3" className="mb-2">
                                    <strong>{t("Labels.verdieping", "Verdieping")}</strong><br/> {installatie.uitTeVoerenWerk?.verdieping}
                                </MDBCol>
                            )}
                            {installatie.uitTeVoerenWerk?.lokaalNummer && (
                                <MDBCol sm="3" className="mb-2">
                                    <strong>{t("Labels.lokaal", "Lokaal")}</strong><br/> {installatie.uitTeVoerenWerk?.lokaalNummer}
                                </MDBCol>
                            )}
                            {installatie.uitTeVoerenWerk?.lokaalNummer && (
                                <MDBCol sm="3" className="mb-2">
                                    <strong>{t("Labels.locatie", "Locatie")}</strong><br/> {installatie.uitTeVoerenWerk?.locatie}
                                </MDBCol>
                            )}
                        </MDBRow>
                    </MDBCol>

                    <MDBCol size="auto"
                            className="d-flex align-items-center justify-content-end">
                        <Icon path={mdiChevronRight} size={2}/>
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );
};
