import React, {useCallback, useMemo} from "react";
import {
    MDBAlert,
    MDBBadge,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCardText,
    MDBCol,
    MDBRow,
    MDBTable,
    MDBTableBody,
    MDBTableHead
} from "mdb-react-ui-kit";
import {Header} from "../../../components/Header";
import Icon from "@mdi/react";
import {
    mdiAlarmLightOutline,
    mdiArchiveArrowUpOutline,
    mdiCheckUnderline,
    mdiChevronRight,
    mdiClockOutline,
    mdiPlus,
    mdiSubdirectoryArrowRight,
    mdiTrashCanOutline
} from "@mdi/js";
import {GenericPage} from "../../../components/GenericPage";
import {useHideModal, useShowModal} from "../../../redux/slices/modal/hooks";
import {FieldServiceModal} from "../../../components/FieldServiceModal";
import {useParams} from "react-router-dom";
import {Adres} from "../../../components/Adres";
import {Form, Formik, FormikProps} from "formik";
import {
    ArtikelRestModel,
    StukToegevoegd,
    ToestelInterventieUitgevoerdControleWerkingResultaatEnum,
    ToestelInterventieUitgevoerdVerbruiktArtikel,
    ToestelOnderhoudUitgevoerdControleWerkingResultaatEnum
} from "../../../_generated/field-service-be-openapi";
import {useArtikelen} from "../../../redux/slices/artikel/hooks";
import {useGoBack} from "../../../routes";
import {useToestelInterventieUitgevoerd} from "../../../redux/slices/toestellen/hooks";
import {useBezoekSessieById, useBezoekSessieToestel} from "../../../redux/slices/bezoeksessie/hooks";
import {BezoekSessieGeslotenMelding} from "../../../components/BezoekSessieGeslotenMelding";
import {BezoekSessieStatus} from "../../../workers/shared/snapshot/bezoekSessieState";
import {ContentContainer} from "../../../components/ContentContainer";
import {BezoekMDBBtn} from "../../../components/bezoek/BezoekMDBBtn";
import {BezoekMDBFormikSelect} from "../../../mdb-formik/bezoek/BezoekMDBFormikSelect";
import {BezoekMDBFormikInput} from "../../../mdb-formik/bezoek/BezoekMDBFormikInput";
import * as Yup from "yup";
import {InterventieOmschrijving} from "./InterventieOmschrijving";
import {DisableAutocompletePlaceholderInput} from "../../../components/DisableAutocompletePlaceholderInput";
import {Datum} from "../../../components/Datum";
import {useTechnici} from "../../../redux/slices/technicus/hooks";
import {parseDate} from "../../../utilities/dateUtils";
import {BezoekFormikTimeInput} from "../../../mdb-formik/bezoek/BezoekFormikTimeInput";
import {useInterventieTijdIngegeven} from "../../../redux/slices/serviceAdressen/hooks";
import {Dayjs} from "dayjs";
import {useTranslation} from "../../../utilities/i18nUtils";
import { BezoekMDBFormikTextArea } from "mdb-formik/bezoek/BezoekMDBFormikTextArea";
import {OnbewaardeGegevensMelding} from "../../../components/OnbewaardeGegevensMelding";
import {SaveDraftFormik} from "../../../components/SaveDraftFormik";


export interface InterventieFormValues {
    verbruikteArtikelen: ToestelInterventieUitgevoerdVerbruiktArtikel[];

    interventieSoort: string | null;
    interventieSubsoort: string | null;

    opmerking: string | null;

    vanTijd: Dayjs | null;
    totTijd: Dayjs | null;

    controleWerkingResultaat: ToestelInterventieUitgevoerdControleWerkingResultaatEnum | null;
    controleWerkingOpmerking: string | null;
}

export interface InterventiePageProps {
    bezoekSessieId: string;
    toestelId: string;
    interventieId: string;
}

export const InterventiePage: React.FC = () => {
    const {bezoekSessieId, toestelId, interventieId} = useParams<InterventiePageProps>();

    const bezoekSessie = useBezoekSessieById(bezoekSessieId);
    const serviceAdres = bezoekSessie?.serviceAdres;

    const bezoekSessieToestel = useBezoekSessieToestel(bezoekSessie?.id, toestelId);
    const bezoekSessieToestelInterventie = bezoekSessieToestel?.interventies?.[interventieId];

    const interventieUitgevoerd = useToestelInterventieUitgevoerd();
    const interventieTijdIngegeven = useInterventieTijdIngegeven();

    const goBack = useGoBack();

    const artikelen = useArtikelen();

    const technici = useTechnici();

    const showModal = useShowModal();
    const hideModal = useHideModal();

    // TODO: analoog zoals bij onderhoud het uit te voeren werk kopiëren naar BezoekSessieToestel?
    const uitTeVoerenInterventie = bezoekSessie?.uitTeVoerenWerk?.toestellen?.find(item => item.toestelId === toestelId)?.interventies?.find(item => item.id === interventieId);

    const {t} = useTranslation("serviceadres");

    const INTERVENTIE_SOORTEN: Record<string, string> = useMemo(() => ({
        BreukToestel: t("InterventiePage.interventieSoorten.breuk-toestel", "Breuk toestel"),
        BreukKraan: t("InterventiePage.interventieSoorten.breuk-kraan", "Breuk kraan"),
        BreukManometer: t("InterventiePage.interventieSoorten.breuk-manometer", "Breuk manometer"),
        BreukDivers: t("InterventiePage.interventieSoorten.breuk-divers", "Breuk divers"),
        Co2: t("InterventiePage.interventieSoorten.co2", "CO₂"),
        Debiet: t("InterventiePage.interventieSoorten.debiet", "Debiet"),
        Koeling: t("InterventiePage.interventieSoorten.koeling", "Koeling"),
        Lek: t("InterventiePage.interventieSoorten.lek-niet-door-breuk", "Lek (niet door breuk)"),
        Smaak: t("InterventiePage.interventieSoorten.smaak", "Smaak"),
        Divers: t("InterventiePage.interventieSoorten.divers", "Divers"),
        Retour: t("InterventiePage.interventieSoorten.retour", "Retour")
    }), [t]);

    const INTERVENTIE_SUBSOORTEN: Record<string, Record<string, string>> = useMemo(() => ({
        BreukToestel: {
            INT0001: t("InterventiePage.InterventieSubsoorten.breukToestel.klep", "Klep"),
            INT0002: t("InterventiePage.InterventieSubsoorten.breukToestel.pomp", "Pomp"),
            INT0003: t("InterventiePage.InterventieSubsoorten.breukToestel.motorpomp", "Motorpomp"),
            INT0004: t("InterventiePage.InterventieSubsoorten.breukToestel.roerstok", "Roerstok"),
            INT0005: t("InterventiePage.InterventieSubsoorten.breukToestel.ventilator", "Ventilator"),
            INT0006: t("InterventiePage.InterventieSubsoorten.breukToestel.compressor", "Compressor"),
            INT0007: t("InterventiePage.InterventieSubsoorten.breukToestel.thermostaat", "Thermostaat"),
            INT0008: t("InterventiePage.InterventieSubsoorten.breukToestel.knoppen", "Knop(pen)"),
            INT0009: t("InterventiePage.InterventieSubsoorten.breukToestel.carbonator", "Carbonator"),
            INT0010: t("InterventiePage.InterventieSubsoorten.breukToestel.printplaat", "Printplaat"),
            INT0011: t("InterventiePage.InterventieSubsoorten.breukToestel.display", "Display"),
            INT0012: t("InterventiePage.InterventieSubsoorten.breukToestel.glasplaat", "Glasplaat"),
            INT0013: t("InterventiePage.InterventieSubsoorten.breukToestel.boiler", "Boiler"),
            INT0014: t("InterventiePage.InterventieSubsoorten.breukToestel.afvoertrechter", "Afvoertrechter")
        },
        BreukKraan: {
            INT0050: t("InterventiePage.InterventieSubsoorten.BreukKraan.keramische-mixer", "Keramische mixer"),
            INT0051: t("InterventiePage.InterventieSubsoorten.BreukKraan.elektronische-display", "Elektronische display"),
            INT0052: t("InterventiePage.InterventieSubsoorten.BreukKraan.elv-printplaat", "ELV-printplaat"),
            INT0053: t("InterventiePage.InterventieSubsoorten.BreukKraan.adapter-12v", "Adapter 12V"),
            INT0054: t("InterventiePage.InterventieSubsoorten.BreukKraan.adapter-24V", "Adapter 24V"),
            INT0055: t("InterventiePage.InterventieSubsoorten.BreukKraan.douchette", "Douchette"),
            INT0056: t("InterventiePage.InterventieSubsoorten.BreukKraan.zwart-t-stuk", "Zwart T-stuk"),
            INT0057: t("InterventiePage.InterventieSubsoorten.BreukKraan.o-ring", "O-ring"),
            INT0058: t("InterventiePage.InterventieSubsoorten.BreukKraan.tappunt", "Tappunt"),
            INT0059: t("InterventiePage.InterventieSubsoorten.BreukKraan.kraanarm", "Kraanarm"),
            INT0060: t("InterventiePage.InterventieSubsoorten.BreukKraan.flexibel", "Flexibel"),
            INT0061: t("InterventiePage.InterventieSubsoorten.BreukKraan.lakwerk", "Lakwerk"),
            INT0062: t("InterventiePage.InterventieSubsoorten.BreukKraan.zwanenhals-g61", "Zwanenhals G61"),
        },
        BreukManometer: {
            INT0150: t("InterventiePage.InterventieSubsoorten.BreukManometer.witte-o-ring", "Witte O-ring"),
            INT0151: t("InterventiePage.InterventieSubsoorten.BreukManometer.venster", "Venster"),
            INT0152: t("InterventiePage.InterventieSubsoorten.BreukManometer.snelkoppeling-jg", "Snelkoppeling JG"),
            INT0153: t("InterventiePage.InterventieSubsoorten.BreukManometer.overdrukventiel", "Overdrukventiel")
        },
        BreukDivers: {
            INT0200: t("InterventiePage.InterventieSubsoorten.BreukDivers.filter", "Filter"),
            INT0201: t("InterventiePage.InterventieSubsoorten.BreukDivers.filterkop", "Filterkop"),
            INT0202: t("InterventiePage.InterventieSubsoorten.BreukDivers.snelkoppeling-jg", "Snelkoppeling JG")
        },
        Co2: {
            INT1001: t("InterventiePage.InterventieSubsoorten.co2.fles-rap-leeg", "Fles rap leeg"),
            INT1002: t("InterventiePage.InterventieSubsoorten.co2.fles-tjlipt", "Fles tjlipt"),
            INT1003: t("InterventiePage.InterventieSubsoorten.co2.manometer-ontregeld", "Manometer ontregeld"),
            INT1004: t("InterventiePage.InterventieSubsoorten.co2.lekkage-niet-door-breuk", "Lekkage (lekkage door breuk? -> gebruik soort 'Breuk')"),
            INT1005: t("InterventiePage.InterventieSubsoorten.co2.co2-omschakeling", "CO₂ omschakeling")
        },
        Debiet: {
            INT2001: t("InterventiePage.InterventieSubsoorten.debiet.laag-debiet-gefilterd-plat-water", "Laag debiet gefilterd plat water"),
            INT2002: t("InterventiePage.InterventieSubsoorten.debiet.geen-gefilterd-water", "Geen gefilterd water (koud & kamer)"),
            INT2003: t("InterventiePage.InterventieSubsoorten.debiet.geen-bruiswater", "Geen bruiswater"),
            INT2004: t("InterventiePage.InterventieSubsoorten.debiet.geen-heet-water", "Geen heet water"),
            INT2005: t("InterventiePage.InterventieSubsoorten.debiet.debiet-te-hoog", "Debiet te hoog"),
            INT2006: t("InterventiePage.InterventieSubsoorten.debiet.geen-stadswater", "Geen stadswater"),
            INT2007: t("InterventiePage.InterventieSubsoorten.debiet.aquastop-dicht", "Aquastop dicht"),
            INT2008: t("InterventiePage.InterventieSubsoorten.debiet.laag-debiet-stadswater", "Laag debiet stadswater")
        },
        Divers: {
            INT3001: t("InterventiePage.InterventieSubsoorten.divers.probleem-opgelost-bij-aankomst", "Probleem opgelost bij aankomst"),
            INT3002: t("InterventiePage.InterventieSubsoorten.divers.werfbezoek-voor-installatie", "Werfbezoek voor installatie"),
            INT3003: t("InterventiePage.InterventieSubsoorten.divers.niet-kunnen-oplossen", "Niet kunnen oplossen"),
            INT3004: t("InterventiePage.InterventieSubsoorten.divers.kraan-stond-los", "Kraan stond los"),
            INT3005: t("InterventiePage.InterventieSubsoorten.divers.dosis-is-veranderd", "Dosis is veranderd"),
            INT3006: t("InterventiePage.InterventieSubsoorten.divers.te-warm-in-de-kast", "Te warm in de kast"),
            INT3007: t("InterventiePage.InterventieSubsoorten.divers.installatie-afgekeurd", "Installatie afgekeurd"),
            INT3008: t("InterventiePage.InterventieSubsoorten.divers.wit-water", "Wit water"),
            INT3009: t("InterventiePage.InterventieSubsoorten.divers.verplaatsing-toestel", "Verplaatsing toestel"),
            INT3010: t("InterventiePage.InterventieSubsoorten.divers.ophalen-toestel", "Ophalen toestel"),
            INT3011: t("InterventiePage.InterventieSubsoorten.divers.water-loopt-automatisch", "Water loopt automatisch"),
            INT3012: t("InterventiePage.InterventieSubsoorten.divers.stuk-vervangen", "Stuk vervangen"),
            INT3013: t("InterventiePage.InterventieSubsoorten.divers.geluid", "Geluid"),
            INT3014: t("InterventiePage.InterventieSubsoorten.divers.a1-filter-exhausted-foutmelding", "'A1 filter exhausted' foutmelding"),
            INT3015: t("InterventiePage.InterventieSubsoorten.divers.opstart-installatie", "Opstart installatie"),
            INT3016: t("InterventiePage.InterventieSubsoorten.divers.extra-onderhoud", "Extra onderhoud"),
            INT3017: t("InterventiePage.InterventieSubsoorten.divers.installatie-na-werkzaamheden", "Installatie na werkzaamheden"),
            INT3018: t("InterventiePage.InterventieSubsoorten.divers.terugplaatsen-toestel", "Terugplaatsen toestel")
        },
        Koeling: {
            INT4001: t("InterventiePage.InterventieSubsoorten.koeling.ice-bank-leeg", "Ice bank leeg"),
            INT4002: t("InterventiePage.InterventieSubsoorten.koeling.foutmelding-a4-pieptoon", "Foutmelding A4 / pieptoon"),
            INT4003: t("InterventiePage.InterventieSubsoorten.koeling.te-weinig-koud-water-licht-verkocht", "Te weinig koud water (licht verkocht...)")
        },
        Lek: {
            INT5001: t("InterventiePage.InterventieSubsoorten.lek.condens", "Condens"),
            INT5002: t("InterventiePage.InterventieSubsoorten.lek.slechte-afvoer-verbinding", "Slechte afvoer verbinding"),
            INT5003: t("InterventiePage.InterventieSubsoorten.lek.slechte-sanitaire-verbinding", "Slechte sanitaire verbinding"),
            INT5004: t("InterventiePage.InterventieSubsoorten.lek.lekbak-overgelopen", "Lekbak overgelopen")
        },
        Smaak: {
            INT6001: t("InterventiePage.InterventieSubsoorten.smaak.fletse-smaak-bruiswater", "Fletse smaak bruiswater"),
            INT6002: t("InterventiePage.InterventieSubsoorten.smaak.slechte-smaak-bruis-plat", "Slechte smaak bruis/plat")
        },
        Retour: {
            INT0063: t("InterventiePage.InterventieSubsoorten.retour.retour-aqualex-voor-herstel", "Retour Aqualex voor herstel")
        }
    }), [t]);

    const onArtikelToevoegenClick = (formik: FormikProps<InterventieFormValues>) => {
        showModal({
            type: FieldServiceModal.STUK_TOEVOEGEN_MODAL,
            props: {
                serviceAdresId: bezoekSessie?.serviceAdres?.id,
                onConfirm: (artikel: ArtikelRestModel) => {
                    formik.setFieldValue("verbruikteArtikelen", [
                        ...(formik.values.verbruikteArtikelen || []),
                        {
                            artikelId: artikel.id,
                            aantal: 1
                        } as StukToegevoegd
                    ]);
                },
                onClose: () => hideModal()
            }
        })
    };

    const onRemoveClick = (item: StukToegevoegd, formik: FormikProps<InterventieFormValues>) => {
        showModal({
            type: FieldServiceModal.CONFIRM_MODAL,
            props: {
                title: t("algemeen:bevestig", "Bevestig"),
                content: t("InterventiePage.weet-u-zeker-dat-dit-stuk-verwijderd-mag-worden", "Weet u zeker dat dit stuk verwijderd mag worden?"),
                confirmColor: "danger",
                onConfirm: () => {
                    formik.setFieldValue("verbruikteArtikelen", (formik.values.verbruikteArtikelen || []).filter((stuk) => stuk.artikelId !== item.artikelId));
                },
                onCancel: () => {

                },
                onClose: () => hideModal()
            }
        });
    };


    const onSubmit = useCallback((values: InterventieFormValues) => {
        if (bezoekSessie) {
            interventieTijdIngegeven({
                bezoekSessieId: bezoekSessie.id,
                interventieId,
                toestelId,
                vanTijd: values.vanTijd!.toISOString(),
                totTijd: values.totTijd?.toISOString()
            });

            interventieUitgevoerd({
                interventieId,
                bezoekSessieId: bezoekSessie.id,
                toestelId,
                soortCode: values.interventieSubsoort!,
                soortLabel: `${values.interventieSubsoort} - ${INTERVENTIE_SUBSOORTEN[values.interventieSubsoort!]}`,
                controleWerking: {
                    resultaat: values.controleWerkingResultaat!,
                    opmerking: values.controleWerkingOpmerking || undefined
                },
                opmerking: values.opmerking || undefined,
                verbruikteArtikelen: (values.verbruikteArtikelen || []) as any
            });

            goBack();
        }
    }, [goBack, bezoekSessie, interventieUitgevoerd, interventieTijdIngegeven, interventieId, toestelId, INTERVENTIE_SUBSOORTEN]);

    const bezoekSessieInterventie = useMemo(() => bezoekSessieToestel?.interventies?.[interventieId], [bezoekSessieToestel, interventieId]);

    const initialValues: InterventieFormValues = useMemo(() => {
        return {
            interventieSubsoort: bezoekSessieInterventie?.soortCode || "",
            controleWerkingResultaat: bezoekSessieInterventie?.controleWerking?.resultaat || null,
            controleWerkingOpmerking: bezoekSessieInterventie?.controleWerking?.opmerking || "",
            interventieSoort: (bezoekSessieInterventie?.soortCode && Object.keys(INTERVENTIE_SUBSOORTEN).find((key) => Object.keys(INTERVENTIE_SUBSOORTEN[key]).includes(bezoekSessieInterventie.soortCode!))) || null,
            verbruikteArtikelen: bezoekSessieInterventie?.verbruikteArtikelen?.map((item) => ({
                artikelId: item.artikelId,
                aantal: item.aantal || 1
            })) || [],
            opmerking: bezoekSessieInterventie?.opmerking || "",

            vanTijd: null,
            totTijd: null
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bezoekSessieInterventie]);

    const interventieSoortIsVerplichtLabel = t("algemeen:Foutmeldingen.interventiesoort-is-verplicht", "Interventiesoort is verplicht");
    const interventieSubSoortIsVerplichtLabel = t("algemeen:Foutmeldingen.interventie-subsoort-is-verplicht", "Interventiesubsoort is verplicht");
    const resultaatIsVerplichtLabel = t("algemeen:Foutmeldingen.resultaat-is-verplicht", "Resultaat is verplicht");
    const vanTijdIsVerplichtLabel = t("algemeen:Foutmeldingen.van-tijd-is-verplicht", "Van-tijd is verplicht");
    const totTijdIsVerplichtLabel = t("algemeen:Foutmeldingen.tot-tijd-is-verplicht", "Tot-tijd is verplicht");
    const gelieveEerstVanTijdInTeVullenLabel = t("algemeen:Foutmeldingen.gelieve-eerst-van-tijd-in-te-vullen", "Gelieve eerst van-tijd in te vullen");
    const totTijdMoetNaVanTijdLiggenLabel = t("algemeen:Foutmeldingen.tot-tijd-moet-na-van-tijd-liggen", "Tot-tijd moet na van-tijd liggen");

    const validationSchema = useMemo(() => Yup.lazy(obj => {
        return Yup.object({
            opmerking: Yup.string().max(1000, t("algemeen:Foutmeldingen.beschrijving-max", "Beschrijving kan maximum {{max}} karakters bevatten.", {max: 1000}) as string).nullable(),

            interventieSoort: Yup.string().nullable().required(interventieSoortIsVerplichtLabel),
            interventieSubsoort: Yup.string().nullable().required(interventieSubSoortIsVerplichtLabel),
            controleWerkingResultaat: Yup.string().required(resultaatIsVerplichtLabel).nullable(),
            controleWerkingOpmerking: Yup.string().nullable(),
            vanTijd: Yup.object().required(vanTijdIsVerplichtLabel).nullable(),
            totTijd: Yup.object().required(totTijdIsVerplichtLabel).nullable()
                .test("vanTijd-required-when-totTijd-is-filled", gelieveEerstVanTijdInTeVullenLabel, function (value) {
                    if (!this.parent.vanTijd && !!value) {
                        return false;
                    }

                    return true;
                })
                .test("totTijd-after-vanTijd", totTijdMoetNaVanTijdLiggenLabel, function (value) {
                    const vanTijd: Dayjs = this.parent.vanTijd;
                    const totTijd = value as unknown as Dayjs;

                    if (!vanTijd || !totTijd) {
                        return true;
                    }

                    if (vanTijd.isValid?.() && totTijd.isValid?.()) {
                        return totTijd.isAfter(vanTijd);
                    }

                    return true;
                }),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), []);

    const vanLabel = t("algemeen:van", "Van");
    const totLabel = t("algemeen:tot", "Tot");
    const beschrijvingUitgevoerdWerkLabel = t("InterventiePage.beschrijving-uitgevoerd-werk",
        "Beschrijving uitgevoerd werk");
    const soortLabel = t("InterventiePage.soort", "Soort");
    const interventieSubsoortLabel = t("InterventiePage.subsoort", "Subsoort");
    const opmerkingOverWerkingLabel = t("InterventiePage.opmerking-over-werking", "Opmerking over werking");

    return (
        <GenericPage>
            <Header title={t("InterventiePage.titel", "Interventie {{plaats}}",
                {plaats: bezoekSessieToestel?.informatie?.locatieBeschrijving || ""})}
                    subtitle={<><strong>{serviceAdres?.naam}</strong> <Adres adres={serviceAdres}/></>}/>

            <ContentContainer>
                <BezoekSessieGeslotenMelding show={bezoekSessie?.status === BezoekSessieStatus.GESLOTEN}/>

                <Formik<InterventieFormValues> onSubmit={onSubmit} initialValues={initialValues}
                                               validationSchema={validationSchema}>
                    {(formik) => (
                        <Form autoComplete="off">
                            <OnbewaardeGegevensMelding draftValues={bezoekSessieToestelInterventie?.draftValues}/>
                            <SaveDraftFormik formId={`interventie/${interventieId}/toestel/${bezoekSessieToestel?.informatie?.id}`} bezoekSessieId={bezoekSessieId}/>

                            <DisableAutocompletePlaceholderInput/>
                            <MDBCard background="light" className="shadow-sm mb-4" style={{zIndex: 100}}>
                                <MDBCardHeader><Icon path={mdiAlarmLightOutline} size={1} className="me-2"/> Interventie</MDBCardHeader>
                                <MDBCardBody>
                                    <MDBCardText>
                                        <MDBRow>
                                            <MDBCol md="4" className="mb-2">
                                                <strong>{t("InterventiePage.ordernummer", "Ordernummer:")}</strong><br/> {uitTeVoerenInterventie?.oorsprong?.bestellingNummer}
                                            </MDBCol>
                                            <MDBCol md="4" className="mb-2">
                                                <strong>{t("InterventiePage.serienummer", "Serienummer:")}</strong><br/> {bezoekSessieToestel?.informatie?.serieNummer}
                                            </MDBCol>
                                            <MDBCol md="4" className="mb-2">
                                                <strong>{t("InterventiePage.toestelnummer", "Toestelnummer:")} </strong><br/> {bezoekSessieToestel?.informatie?.nr}
                                            </MDBCol>
                                            <MDBCol md="12" className="mb-2">
                                                <strong>{t("InterventiePage.omschrijving", "Omschrijving:")} </strong><br/>

                                                <InterventieOmschrijving taakType={uitTeVoerenInterventie?.taakType}
                                                                         omschrijving={uitTeVoerenInterventie?.omschrijving}/>
                                            </MDBCol>
                                            {!!uitTeVoerenInterventie?.meeTeGevenArtikels?.length && (
                                                <MDBCol md="12" className="mb-2">
                                                    <div><strong>{t("InterventiePage.artikelen", "Artikelen:")}</strong>
                                                    </div>

                                                    <MDBAlert color="info" open className="mt-2">
                                                        {t("InterventiePage.laat-je-artikels-uit-deze-lijst-achter-bij-de-klant-geef-ze-dan-in", "Laat je artikels uit deze lijst achter bij de klant, geef ze dan in via \"Verbruikte artikelen\".")}
                                                    </MDBAlert>

                                                    <MDBTable responsive>
                                                        <MDBTableHead>
                                                            <tr>
                                                                <th scope="col">{t("algemeen:Titels.aantal", "Aantal")}</th>
                                                                <th scope="col">{t("algemeen:Titels.artikelnr", "Artikelnr.")}</th>
                                                                <th scope="col">{t("algemeen:Titels.omschrijving", "Omschrijving")}</th>
                                                            </tr>
                                                        </MDBTableHead>
                                                        <MDBTableBody>
                                                            {!uitTeVoerenInterventie?.meeTeGevenArtikels?.length && (
                                                                <tr>
                                                                    <td colSpan={3}>{t("InterventiePage.geen-mee-te-geven-artikels", "Geen onderdelen")}</td>
                                                                </tr>
                                                            )}
                                                            {uitTeVoerenInterventie?.meeTeGevenArtikels?.map(item => {
                                                                return (
                                                                    <tr>
                                                                        <td>{item.aantal || 1} x</td>
                                                                        <td className="align-middle">
                                                                            <strong>{item.artikelNr}</strong>
                                                                        </td>
                                                                        <td className="align-middle">
                                                                            {item.artikelOmschrijving}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </MDBTableBody>
                                                    </MDBTable>
                                                </MDBCol>
                                            )}
                                        </MDBRow>
                                    </MDBCardText>
                                </MDBCardBody>
                            </MDBCard>

                            <h6 className="mb-3">
                                <Icon path={mdiClockOutline}
                                      size={1}/> {t("InterventiePage.tijdsregistratie", "Tijdsregistratie")}
                            </h6>
                            <MDBCard className="shadow-sm mb-3" background="light">
                                <MDBCardBody size="12">
                                    {Object.keys(bezoekSessieToestelInterventie?.technicusTijden || {}).length === 0 && (
                                        <span className="text-muted">{t("InterventiePage.nog-geen-tijden-ingegeven",
                                            "Nog geen tijden ingegeven.")}
                                        </span>
                                    )}
                                    {Object.values(bezoekSessieToestelInterventie?.technicusTijden || {})?.map((tijd) => {
                                        const technicus = technici[tijd.technicusId];

                                        return (
                                            <div className="d-flex align-items-center">
                                                <MDBBadge>
                                                    <Datum format="HH:mm" datum={tijd.vanTijd}/>
                                                </MDBBadge>

                                                <Icon path={mdiChevronRight} size={0.8}/>

                                                <MDBBadge className="me-3">
                                                    <Datum format="HH:mm" datum={tijd.totTijd}/>
                                                </MDBBadge>

                                                <span>
                                                        {technicus.naam}
                                                </span>
                                            </div>
                                        );
                                    })}
                                </MDBCardBody>
                            </MDBCard>

                            <MDBRow className="mb-3">
                                <MDBCol size="6" onClick={() => formik.setFieldValue("vanTijd", parseDate())}>
                                    <MDBBtn type="button" color="primary" block size="lg">
                                        {t("InterventiePage.interventie-inchecken", "Interventie inchecken")}</MDBBtn>
                                </MDBCol>
                                <MDBCol size="6" onClick={() => formik.setFieldValue("totTijd", parseDate())}>
                                    <MDBBtn type="button" color="primary" block size="lg">
                                        {t("InterventiePage.interventie-uitchecken", "Interventie uitchecken")}
                                    </MDBBtn>
                                </MDBCol>
                            </MDBRow>

                            <MDBRow className="mb-3">
                                <MDBCol size="6">
                                    <BezoekFormikTimeInput label={vanLabel} name="vanTijd"/>
                                </MDBCol>

                                <MDBCol size="6">
                                    <BezoekFormikTimeInput label={totLabel} name="totTijd"/>
                                </MDBCol>
                            </MDBRow>

                            <MDBRow className="d-flex align-items-center">
                                <MDBCol className="d-flex align-items-center">
                                    <h6><Icon path={mdiArchiveArrowUpOutline} size={1} className="me-2"/>
                                        {t("InterventiePage.verbruikt-artikelen", "Verbruikte artikelen")}
                                    </h6>
                                </MDBCol>
                                <MDBCol size="auto">
                                    <BezoekMDBBtn onClick={() => onArtikelToevoegenClick(formik)} type="button">
                                        <Icon path={mdiPlus} size={1}/>
                                        {t("InterventiePage.artikel-toevoegen", "Artikel toevoegen")}
                                    </BezoekMDBBtn>
                                </MDBCol>
                            </MDBRow>

                            <MDBRow className="mb-3">
                                <MDBCol size="12">
                                    <MDBTable responsive borderless striped>
                                        <MDBTableHead>
                                            <tr>
                                                <th scope="col">{t("algemeen:Titels.artikelnr-omschrijving", "Artikelnr. - Omschrijving")}</th>
                                                <th scope="col">{t("algemeen:Titels.aantal", "Aantal")}</th>
                                                <th scope="col"/>
                                            </tr>
                                        </MDBTableHead>
                                        <MDBTableBody>
                                            {!formik.values.verbruikteArtikelen?.length && (
                                                <tr>
                                                    <td colSpan={4}>
                                                        {t("InterventiePage.geen-verbruikte-artikelen-toegevoegd",
                                                            "Geen verbruikte artikelen toegevoegd")}
                                                    </td>
                                                </tr>
                                            )}

                                            {formik.values.verbruikteArtikelen?.map((item, index) => {
                                                const artikel = artikelen[item.artikelId];

                                                return (
                                                    <tr>
                                                        <td className="align-middle">
                                                            <strong>{artikel?.nummer}</strong> {artikel?.omschrijving}
                                                        </td>
                                                        <td className="align-middle">
                                                            <BezoekMDBFormikInput type="number" step="1"
                                                                                  name={`verbruikteArtikelen[${index}].aantal`}
                                                                                  wrapperStyle={{maxWidth: "5rem"}}/>
                                                        </td>
                                                        <td className="align-middle text-right text-nowrap">
                                                            <BezoekMDBBtn color="link" className="px-2 py-1"
                                                                          onClick={() => onRemoveClick(item, formik)}>
                                                                <Icon path={mdiTrashCanOutline} size={1}/>
                                                            </BezoekMDBBtn>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </MDBTableBody>
                                    </MDBTable>
                                </MDBCol>
                            </MDBRow>

                            <h6 className="mb-3"><Icon path={mdiCheckUnderline} size={1}/> Uitgevoerd werk</h6>
                            <MDBRow className="mb-2">
                                <MDBCol size="12" className="mb-3">
                                    <BezoekMDBFormikTextArea label={beschrijvingUitgevoerdWerkLabel} id="opmerking"
                                                          name="opmerking"
                                                          rows={4}/>
                                </MDBCol>

                                <MDBCol size="12" className="mb-3">
                                    <BezoekMDBFormikSelect
                                        label={soortLabel}
                                        name="interventieSoort"
                                        data={Object.entries(INTERVENTIE_SOORTEN).map(([key, value]) => ({
                                            text: value,
                                            value: key
                                        }))}

                                        onChange={() => {
                                            formik.setFieldValue("interventieSubsoort", "");
                                        }}
                                    />
                                </MDBCol>

                                {formik.values.interventieSoort && INTERVENTIE_SUBSOORTEN[formik.values.interventieSoort] && (
                                    <MDBCol size="12" className="mb-3">
                                        <BezoekMDBFormikSelect key={formik.values.interventieSoort}
                                                               label={interventieSubsoortLabel}
                                                               name="interventieSubsoort"
                                                               data={Object.entries(INTERVENTIE_SUBSOORTEN[formik.values.interventieSoort]).map(([key, value]) => ({
                                                                   text: `${key} - ${value}`,
                                                                   value: key
                                                               }))}
                                        />
                                    </MDBCol>
                                )}

                                <MDBCol className="mb-3">
                                    <div>
                                        <BezoekMDBFormikSelect
                                            name="controleWerkingResultaat"
                                            label="Controle werking"
                                            data={[
                                                {
                                                    text: t("ControleWerkingResultaat.ok", "Oké") as string,
                                                    value: ToestelOnderhoudUitgevoerdControleWerkingResultaatEnum.Oke
                                                },
                                                {
                                                    text: t("ControleWerkingResultaat.ok-maar-opvolging-vereist", "Oké, maar opvolging vereist") as string,
                                                    value: ToestelOnderhoudUitgevoerdControleWerkingResultaatEnum.OkeOpvolgingVereist
                                                },
                                                {
                                                    text: t("ControleWerkingResultaat.defect-interventie-plannen", "Defect: interventie plannen") as string,
                                                    value: ToestelOnderhoudUitgevoerdControleWerkingResultaatEnum.Defect
                                                }
                                            ]}
                                        />

                                        {formik.values?.controleWerkingResultaat && [
                                            ToestelInterventieUitgevoerdControleWerkingResultaatEnum.OkeOpvolgingVereist,
                                            ToestelInterventieUitgevoerdControleWerkingResultaatEnum.Defect]
                                            .includes(formik.values?.controleWerkingResultaat) && (
                                            <MDBRow className="gx-0">
                                                <MDBCol size="auto">
                                                    <Icon path={mdiSubdirectoryArrowRight} size={2}/>
                                                </MDBCol>

                                                <MDBCol>
                                                    <BezoekMDBFormikTextArea name="controleWerkingOpmerking"
                                                                          className="mt-2"
                                                                          label={opmerkingOverWerkingLabel}
                                                                          rows={4}/>
                                                </MDBCol>
                                            </MDBRow>
                                        )}
                                    </div>
                                </MDBCol>

                                <MDBCol size="12">
                                    <BezoekMDBBtn block size="lg" type="submit">{t("algemeen:bewaren", "Bewaren")}</BezoekMDBBtn>
                                </MDBCol>
                            </MDBRow>
                        </Form>
                    )}
                </Formik>

            </ContentContainer>
        </GenericPage>
    );
};
