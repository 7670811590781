import React, {useState} from "react";
import {MDBBtn, MDBModalBody, MDBModalFooter, MDBModalHeader} from "mdb-react-ui-kit";
import {PopupModal} from "./PopupModal";
import {Config} from "../../utilities/config";
import {Snackbar} from "../Snackbar";
import {useTranslation} from "../../utilities/i18nUtils";
import {Trans} from "react-i18next";

export interface ErrorModalProps {
    error?: Error;

    onClose?(): void;
}

export const ErrorModal: React.FC<ErrorModalProps> = (props) => {
    const {error, onClose} = props;

    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const {t} = useTranslation("algemeen");

    const onIgnoreClick = () => {
        onClose?.();
    };

    const onSendClick = () => {
        const errorTitle = t("ErrorModal.titel",
            "Field Service {{versie}} - Foutmelding",
            {versie: Config.VERSION});

        const errorText = t("ErrorModal.foutTekst", "Foutmelding Field Service {{versie}}: {{foutNaam}} {{foutboodschap}}\n\nStack: {{stack}}", {
            versie: Config.VERSION,
            foutNaam: error?.name,
            foutboodschap: error?.message,
            stack: error?.stack
        });

        if ("share" in navigator) {
            navigator.share({
                title: errorTitle,
                text: errorText
            });
        } else {
            // @ts-ignore
            navigator.clipboard.writeText(errorText);
            setSnackbarOpen(true);
        }
    };

    const informatieNaarKlembordGekopieerd = t("ErrorModal.informatie-naar-klembord-gekopieerd",
        "Informatie naar klembord gekopieerd");

    return (
        <PopupModal modalDialogOptions={{centered: true, size: "lg"}}>
            <MDBModalHeader className="bg-danger text-white">
                {t("ErrorModal.foutmelding",
                    "Foutmelding")}
            </MDBModalHeader>
            <MDBModalBody>
                {t("ErrorModal.er-heeft-zich-een-fout-voorgedaan", "Er heeft zich een fout voorgedaan. Hieronder kan je de technische informatie over de fout. Je kan deze informatie delen om ons op de hoogte te stellen.")}

                <pre className="mt-2" style={{maxHeight: "10rem"}}>
                        <>
                            {{error: error?.message || error?.name}}<br/><br/>

                            Stacktrace:<br/>
                            <code>
                                <>
                                    {{stack: error?.stack}}
                                </>
                            </code>
                        </>
                    </pre>

            </MDBModalBody>
            <MDBModalFooter>
                <MDBBtn type="button" outline color="secondary"
                        onClick={onIgnoreClick}>{t("ErrorModal.negeren", "Negeren")}</MDBBtn>
                <MDBBtn type="button" color="primary" onClick={onSendClick}>{t("ErrorModal.delen", "Delen")}</MDBBtn>
            </MDBModalFooter>

            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}
                      className="mb-6"
                      message={informatieNaarKlembordGekopieerd}/>
        </PopupModal>
    );
};
